import { Await, Link } from 'react-router';
import { MoveRight } from 'lucide-react';
import React from 'react';
import { Loop } from '~/components/Loop';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/ui/Carousel';
import { Image } from '~/components/ui/Image';
import { HeadingL, HeadingS, textVariants } from '~/components/ui/Typography';
import { useTranslation } from '~/i18n';
import type { loader } from '../routes/_app._index/route';
import { buttonVariants } from './ui/Button';

type Loader = Awaited<ReturnType<typeof loader>>;

interface Props {
  data: Extract<Loader, { viewer: undefined }>;
}

export function BlogPosts({ data }: Props) {
  const { t } = useTranslation();

  return (
    <React.Suspense fallback={<Placeholder />}>
      <Await resolve={data.blogPosts}>
        {(posts) => {
          const _posts = [...posts];
          const firstPost = _posts.shift() as (typeof _posts)[0];

          return (
            <div>
              <article className="mb-[72px] grid items-center gap-4 md:mb-10 md:grid-cols-[55%__auto] md:gap-10">
                <Image
                  height={389}
                  className="block size-full max-h-56 rounded-lg object-cover md:h-full md:max-h-[389px]"
                  src={firstPost.yoast_head_json.og_image[0].url}
                  alt={firstPost.yoast_head_json.og_description}
                />

                <div className="relative flex h-full max-w-prose flex-col gap-y-4 text-left md:justify-center">
                  <Loop className="absolute -top-6 hidden h-12 text-surface-pink xl:block" />
                  <HeadingL
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust the data
                    dangerouslySetInnerHTML={{
                      __html: firstPost?.title.rendered,
                    }}
                  />

                  {firstPost?.excerpt.rendered ? (
                    <div
                      className={textVariants({
                        size: 'xl',
                        className: 'line-clamp-4 break-all font-medium',
                      })}
                      // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust the data
                      dangerouslySetInnerHTML={{
                        __html: firstPost.excerpt.rendered,
                      }}
                    />
                  ) : null}

                  <div className="pt-2 text-left">
                    <Link
                      to={firstPost.link}
                      className={buttonVariants({
                        variant: 'link',
                        class: 'px-0 text-on no-underline',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('read-more')} <MoveRight className="size-6" />
                    </Link>
                  </div>
                </div>
              </article>

              <Carousel className="w-full">
                <CarouselContent className="lg:-ml-10">
                  {_posts.map((post) => (
                    <CarouselItem key={post.id} className="sm:basis-1/2 lg:basis-1/3 lg:pl-10">
                      <Post
                        alt={post.yoast_head_json.og_description}
                        excerpt={post.excerpt.rendered}
                        src={post.yoast_head_json.og_image[0].url}
                        title={post.title.rendered}
                        url={post.link}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>

                <div className="mt-10 flex items-center justify-center gap-4 md:hidden">
                  <CarouselPrevious
                    className="relative left-auto top-auto size-12 translate-y-0 rounded-lg"
                    variant="tertiary"
                    size="icon"
                  />
                  <CarouselNext
                    className="relative right-auto top-auto size-12 translate-y-0 rounded-lg"
                    variant="tertiary"
                    size="icon"
                  />
                </div>
              </Carousel>
            </div>
          );
        }}
      </Await>
    </React.Suspense>
  );
}

function Post(props: {
  src: string;
  alt: string;
  title: string;
  excerpt: string;
  url: string;
}) {
  const { t } = useTranslation();

  return (
    <article>
      <Image
        height={260}
        className="mb-8 h-[212px] w-full rounded-lg object-cover md:h-[260px]"
        src={props.src}
        alt={props.alt}
      />

      <HeadingS className="mb-6" asChild>
        <h3 // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust the data
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </HeadingS>

      <div
        className={textVariants({
          size: 'm',
          className: 'mb-6 line-clamp-2 font-medium',
        })}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust the data
        dangerouslySetInnerHTML={{ __html: props.excerpt }}
      />

      <Link
        to={props.url}
        target="_blank"
        rel="noreferrer"
        className={buttonVariants({
          variant: 'link',
          class: 'px-0 text-on no-underline',
        })}
      >
        {t('read-more')} <MoveRight className="ml-2 size-6" />
      </Link>
    </article>
  );
}

function Placeholder() {
  return (
    <div>
      <div className="mb-[72px] grid items-center gap-4 md:mb-10 md:grid-cols-[55%__auto] md:gap-10">
        <div className="block h-56 w-full animate-pulse rounded-lg bg-surface-on/30 md:h-[389px]" />
        <div className="relative flex h-full max-w-prose flex-col gap-y-4 text-center md:justify-center md:text-left">
          <Loop className="absolute -top-6 hidden h-12 text-surface-pink md:block" />
          <div className="h-12 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-[88px]" />
          <div className="h-24 w-full animate-pulse rounded-sm bg-surface-on/30" />
          <div className="h-10 w-full animate-pulse rounded-sm bg-surface-on/30" />
        </div>
      </div>

      <div className="grid md:grid-cols-3 md:gap-10">
        <div>
          <div className="mb-8 block h-[212px] w-full animate-pulse rounded-lg bg-surface-on/30 md:h-[260px]" />
          <div className="mb-6 h-11 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="mb-6 h-10 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="h-10 w-full animate-pulse rounded-sm bg-surface-on/30" />
        </div>

        <div className="hidden md:block">
          <div className="mb-8 block h-[212px] w-full animate-pulse rounded-lg bg-surface-on/30 md:h-[260px]" />
          <div className="mb-6 h-11 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="mb-6 h-10 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="h-10 w-full animate-pulse rounded-sm bg-surface-on/30" />
        </div>

        <div className="hidden md:block">
          <div className="mb-8 block h-[212px] w-full animate-pulse rounded-lg bg-surface-on/30 md:h-[260px]" />
          <div className="mb-6 h-11 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="mb-6 h-10 w-full animate-pulse rounded-sm bg-surface-on/30 md:h-12" />
          <div className="h-10 w-full animate-pulse rounded-sm bg-surface-on/30" />
        </div>
      </div>
    </div>
  );
}
